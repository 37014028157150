import LanguageIcon from "@mui/icons-material/Language";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import i18n from "../i18n";
import "./styles/LanguageSelector.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSelector = () => {
  const [showLanguageList, setShowLanguageList] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languages = [
    { code: "de", name: "Deutsch" },
    { code: "en", name: "English" },
    { code: "it", name: "Italiano" },
    { code: "fr", name: "Français" },
  ];

  const chooseLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setShowLanguageList(false);
  };

  const toggleLanguageList = () => {
    setShowLanguageList(!showLanguageList);
  };

  return (
    <div className="language-selector">
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="primary"
        onClick={toggleLanguageList}
      >
        <LanguageIcon sx={{ fontSize: 25 }} />
      </IconButton>

      {showLanguageList && (
        <ul className="language-list" onMouseLeave={toggleLanguageList}>
          {languages.map((lang) => (
            <li
              key={lang.code}
              onClick={() => chooseLanguage(lang.code)}
              className={selectedLanguage === lang.code ? "selected" : ""}
            >
              <span
                className={`fi fi-${lang.code === "en" ? "gb" : lang.code}`}
              ></span>
              <span className="language-name">{lang.name}</span>
              {selectedLanguage === lang.code && (
                <FaCheck className="checkmark-icon" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
