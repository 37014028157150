import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import * as React from "react";
import { statusColor } from "../utils";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Skeleton from '@mui/material/Skeleton';

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ImageComponent from "../ImageComponent";
import { useTranslation } from "react-i18next";

const imgGridStyle = {
  margin: "auto",
  width: "auto",
  maxWidth: "90%",
  padding: 1,
  overflow: "hidden",
};

export default function BookListItem(props) {
  const id = props.id;
  const divider = props.divider;
  const title = props.title;
  const bookDesc = props.bookDesc;
  const imgUrl = props.imgUrl;
  const author = props.author;
  const borrower = props.borrower;
  const isMobile = props.isMobile;
  const deadlineDate = props.deadlineDate;
  const loanDate = props.loanDate;

  const [isHovered, setIsHovered] = React.useState(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  console.log("IS MOBILE ", isMobile)
  console.log("BORROWER", borrower)

  return (
    <ListItem
      disablePadding
      style={{
        position: "relative",
        boxShadow: isHovered
          ? "0px 4px 8px rgba(0, 0, 0, 0.3)"
          : "0px 4px 8px rgba(0, 0, 0, 0.15)",
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ListItemButton
        LinkComponent={Link}
        to={`/manage-book/${id}`}
        divider={divider}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          orientation="row"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <ListItemAvatar>
              <Avatar>
                <ImageComponent imgUrl={imgUrl} style={imgGridStyle} />
              </Avatar>
            </ListItemAvatar>

            <Box marginLeft={2}>
              <ListItemText
                primary={title}
                secondary={
                  author.length > 0 ? author.join(", ") : "Unknown Authors"
                }
              />

              <Box display="flex" alignItems="center">
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: statusColor(bookDesc),
                    marginRight: "5px",
                  }}
                />
                <ListItemText secondary={t(bookDesc)} />
              </Box>
            </Box>
          </Box>

          {borrower !== undefined && !isMobile && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <AccountCircleIcon />
                <ListItemText secondary={borrower.email} />
                <ListItemText secondary={borrower.phone} />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  left: "75%",
                  transform: "translateX(-75%)",
                  textAlign: "center",
                }}
              >
                <ScheduleIcon />
                <ListItemText
                  secondary={"Borrowed : "+loanDate}
                    /** 
                    <Typography variant="body2">
                      {"Borrowed : "}
                      <span style={{ fontWeight: "bold" }}>{loanDate}</span>
                    </Typography>
                    */
                />
                <ListItemText
                  secondary={"Due : "+deadlineDate
                  /** 
                    <Typography variant="body2">
                      {"Due : "}
                      <span style={{ fontWeight: "bold" }}>{deadlineDate}</span>
                    </Typography>
                  */
                  }
                />
              </Box>
            </>
          )}

          <ListItemIcon>
            <ArrowForwardIosIcon fontSize="small" />
          </ListItemIcon>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
