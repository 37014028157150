import {
  Box, 
  Typography
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import "./App.css";
import AppHeader from "./components/AppHeader";
import { theme } from "./theme/theme";

function App() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="scrollable-content"
        sx={{
          backgroundColor: "white",
          height: "100vh", // Set the height to the viewport height
        }}
      >
        <AppHeader/>
        <Outlet/>
      </Box>
    </ThemeProvider>
  );
}

export default App;
