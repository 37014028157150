import { Book } from "../models/Book";

// Server URL -> to change with the production URL before deployment
const url = process.env.REACT_APP_BACKEND_URL || "";
const loginURL = `${url}/auth`;
const serverURL = `${url}/record`;

export class ServerOperations {
  // Perform login procedure
  static async login(username, password) {
    const response = await fetch(`${loginURL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    if (response.ok) {
      return [data, null];
    } else {
      return [null, data.message];
    }
  }

  // Fetch a book given its ID
  static async getBook(id) {
    try {
      const response = await fetch(`${serverURL}/book/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const book = await response.json();
      if (!book) {
        window.alert(`Record with id ${id} not found`);
        return;
      }

      //console.log("BOOK DB : ", Book.fromResponse(book))

      return Book.fromResponse(book);
    } catch (e) {
      return undefined;
    }
  }

  // Fetch a book given its ID
  static async requestLoanExtension(id) {
    console.log('DEBUG: requestLoanExtension')
    try {
      const response = await fetch(
        `${serverURL}/book/${id}/request-extension`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (e) {
      return "An error occured during the loan extension request.";
    }
  }

  // Adds a new borrower & sends request to have the book stated as borrowed to the server
  static async setBookAsLoaned(bookID, email, phone) {
    const response = await fetch(`${serverURL}/book/${bookID}/set-loaned`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([email, phone]),
    });
    return response;
  }

  // Sets the book as available to the server
  static async setBookAsAvailable(bookID, auth, email) {
    const response = await fetch(`${serverURL}/book/${bookID}/set-available`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.accessToken}`,
      },
      body: (auth && auth.email) ? JSON.stringify([auth.email]) : null,
    });
    return response;
  }

  // Notify borrower to return the book
  static async notifyBorrower(bookID, auth, email) {
    console.log("DEBUG");
    console.log(bookID);
    console.log(email);
    const response = await fetch(`${serverURL}/book/${bookID}/notify-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.accessToken}`,
      },
      body: JSON.stringify(email),
    });
    return response;
  }

  // Sets the book as unavailable to the server
  static async setBookAsUnAvailable(bookID, auth) {
    const response = await fetch(
      `${serverURL}/book/${bookID}/set-unavailable`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
      }
    );
    return response;
  }

  // Adds a new book instance in the library database
  static async addBook(auth, id, isbn, title, author, preview, bookData) {
    const response = await fetch(
      `${serverURL}/book/add-new-book`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
        body: JSON.stringify({
          id: id,
          isbn: isbn,
          title: title,
          author: author,
          preview: preview, 
          bookData
        }),
      }
    );

    //let response_text = await response.text()
    //console.log("SERVER OP", response_text)
    return response;
  }

  // Deletes a book given its ID
  static async deleteBook(id, auth) {
    return await fetch(`${serverURL}/book/${id}/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.accessToken}`,
      },
    });
  }

  // Retrieves all books in database
  static async getBooks(auth) {
    try {
      const response = await fetch(`${serverURL}/books`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const books = await response.json();
      console.log("DB ", books)

      // filter books by username
      var b = books.map((book) =>
        Book.fromResponse(book)
      );
      
      // filter books by username
      b = b.filter(
        (book) => book.getLibrary().toLowerCase() === auth.name.toLowerCase()
      );
      
      //console.log("DB", b);
      return b;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  // Retrieves all books in database
  static async searchBook(auth, searchInput) {
    try {
      const response = await fetch(`${serverURL}/books`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const books = await response.json();
      // filter books by username
      var b = books.map((book) => Book.fromResponse(book));
      // filter books by username
      b = b.filter(
        (book) => book.getLibrary().toLowerCase() === auth.name.toLowerCase()
      );
      return b;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
