import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEng from './locales/en/translation.json';
import translationsInGerman from './locales/de/translation.json';
import translationsInItalian from './locales/it/translation.json';
import translationsInFrench from './locales/fr/translation.json';

const resources = {
  en: {
    translation: translationsInEng
  },
  de: {
    translation: translationsInGerman
  },
  it: {
    translation: translationsInItalian
  },
  fr: {
    translation: translationsInFrench
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources, // translated content
    lng: "en", // language on site load 
    debug: true,
    fallbackLng: "en", // if language not available
    interpolation: {
      escapeValue: false
    },
    ns: "translation", // namespaces help to divide huge translations into multiple small files
    defaultNS: "translation"
  });

export default i18n;
