import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Dialog, DialogTitle, Hidden, IconButton } from "@mui/material";
import "../../i18n";
import HelpDialogContent from "./HelpDialogContent";

export const HelpDialog = ({ handleCloseHelpDialog, helpDialogOpen }) => {
  return (
    <>
      {/* Help Dialog */}
      <Dialog
        open={helpDialogOpen}
        onClose={handleCloseHelpDialog}
        fullWidth
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Hidden>
              <HelpIcon sx={{ fontSize: 0 }} />
            </Hidden>

            <HelpIcon sx={{ fontSize: 32 }} />
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleCloseHelpDialog}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <HelpDialogContent />
      </Dialog>
    </>
  );
};
