import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ImageComponent from "../ImageComponent";
import { statusColor } from "../utils";
import * as React from "react";

export default function BookGridItem(props) {
  const { t } = useTranslation();
  const imgUrl = props.imgUrl;
  const bookId = props.id;
  const authorNames = props.authors;
  const bookTitle = props.bookTitle;
  const bookLoanStatusString = props.bookLoanStatusString;
  const bookLoaner = props.bookLoaner;
  const isBookLoaned = props.isBookLoaned;
  const bookLoanDate = props.bookLoanDate;
  const isClickable = props.isClickable;
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const imgGridStyle = {
    margin: "auto",
    width: "auto",
    maxWidth: "90%",
    minHeight: 250,
    maxHeight: 350,
    padding: 1,
    overflow: "hidden",
  };

  const cardContent = (
    <Card
      variant="outlined"
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: isHovered ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none",
        transition: "box-shadow 0.3s ease-in-out", // Add transition for smooth effect
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <CardMedia title={bookTitle}>
        <ImageComponent imgUrl={imgUrl} style={imgGridStyle} />
      </CardMedia>
  
      <CardContent style={{ flexGrow: 1, position: "relative" }}>
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "1.25em",
            position: "relative",
          }}
        >
          {bookTitle}
        </Typography>
  
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            position: "relative",
          }}
        >
          <strong>
            {" "}
            {authorNames.length > 0 ? authorNames.join(", ") : "Unknown Authors"}
          </strong>
        </Typography>
  
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: statusColor(bookLoanStatusString),
              marginRight: "5px",
            }}
          />
          {t(bookLoanStatusString)}
        </Typography>
  
        {isBookLoaned && (
          <Typography variant="body1" color="text.secondary">
            {"Contact : "}
            <strong> {bookLoaner} </strong> <br />
            {bookLoanDate}
          </Typography>
        )}
  
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            marginRight: "1%",
          }}
        >
          {isClickable && <ArrowForwardIosIcon fontSize="very-small" />}
        </div>
      </CardContent>
    </Card>
  );

  return (
    <Grid item key={bookId} xs={12} sm={6} md={12 / 4} lg={12 / 5}>
      {isClickable ? (
        <Link
          to={`/manage-book/${bookId}`}
          style={{
            textDecoration: "none",
          }}
        >
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </Grid>
  );
}
