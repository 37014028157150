import HelpIcon from "@mui/icons-material/Help";
import MailIcon from "@mui/icons-material/Mail";
import {
    Badge,
    Box,
    IconButton
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import "../../i18n";
import LanguageSelector from "../LanguageSelector";

export const AdditionalIconsSection = ({
  handleOpenHelpDialog,
  handleDrawerNotifOpen,
  isMobile
}) => {
  const auth = useAuthContext();

  return (
    (<Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flex: 2,
      }}
    >
      {/* TODO : Fix feature in future PR
      !isMobile && auth && auth.username && (
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            color="primary"
            onClick={handleDrawerNotifOpen}
          >
            <MailIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </div>
      )*/}

      {auth && auth.username && <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="primary"
        onClick={handleOpenHelpDialog}
      >
        <HelpIcon sx={{ fontSize: 32 }} />
      </IconButton>}
      <LanguageSelector />
    </Box>
  ));
};
