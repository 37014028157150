export class Borrower {
    private email: string
    private phone: number | undefined

    constructor(email: string, phone: number | undefined) {
        this.email = email
        this.phone = phone
    }

    // Creates new Borrower from a parsed JSON response
    public static fromResponse(response: any): Borrower | undefined {
        if (
            response.hasOwnProperty('borrower_infos')
            && response.borrower_infos.hasOwnProperty('email')
        ) {
            if (response.borrower_infos.hasOwnProperty('phone')) {
                return new Borrower(
                    response.borrower_infos.email,
                    response.borrower_infos.phone,
                )
            } else {
                return new Borrower(
                    response.borrower_infos.email,
                    undefined,
                )
            }
        } else {
            return undefined
        }
    }

    // Borrower setters/getters
    public getEmail(): string {
        return this.email
    }

    public getPhone(): number | undefined {
        return this.phone
    }
    
    public toString(): string {
        return `${this.email} (Mobile: ${this.phone !== undefined ? this.phone : '-'})`
    }
}
