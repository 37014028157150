import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";

import {
    Box,
    TextField
} from "@mui/material";
import "../../i18n";

export const SearchBar = ({ isSearchBarExpanded, handleSubmit, handleChange, handleExpanded, closeSearchBar, isMobile }) => {
  const searchBarRef = React.useRef(null);

  const handleClickOutside = (event) => {
    
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      // Click occurred outside the search bar, close it
      closeSearchBar();
    }
  };

  React.useEffect(() => {
    // Add click event listener to the document body
    document.body.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [isSearchBarExpanded, handleExpanded, isMobile]);

  return (
    <>
      <Box
        component="div"
        ref={searchBarRef}
        onClick={handleExpanded}
        sx={{
          flex: isSearchBarExpanded ? 100 : 80,
          borderRadius: 10,
          backgroundColor: "grey.200",
          paddingLeft: "3%",
          paddingRight: "1%",
          paddingTop: "0.5%",
          paddingBottom: "0.5%",
          display: "flex",
          alignItems: "center",
          justifyContent: 'flex-start',
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          transition: 'flex 0.9s ease',
        }}
      >
        <>
        <SearchIcon color="primary" />
        <form onSubmit={handleSubmit}>
          {<TextField
            label=""
            placeholder=""
            onChange={handleChange}
            sx={{
              marginLeft: 1,
              fontSize: 20,
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent", // Remove outline color
                },
                "&:hover fieldset": {
                  borderColor: "transparent", // Remove outline color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Remove outline color when focused
                },
              },
            }}
            InputLabelProps={{
              shrink: false, // Disable the label shrink animation
            }}
          />}
        </form>
        </>
      </Box>
      
    </>
  );
};
