import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { Form } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { passwordErrorMessage, usernameErrorMessage } from "../lang/constants";
import animationData from "../theme/pageTurn.json";

export async function action() {
  console.log("You pressed the button!");
  return null;
}

export default function LoginPage() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { login, loading, error } = useLogin();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { t, i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(username, password);
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          position: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
          width: 400,
        }}
      >
        <Stack
          spacing={3}
          sx={{
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Lottie options={defaultOptions} height={100} width={100} />

          <Typography variant="h4" align="center">
            {t("loginTitle")}
          </Typography>
        </Stack>

        {/* Form part */}
        <Form method="post" onSubmit={handleSubmit}>
          <Stack spacing={1}>
            <FormControl error={error != null}>
              <Stack spacing={1}>
                <TextField
                  aria-describedby="username-error-text"
                  color={error === t("usernameErrorMessage") && "error"}
                  label={t("usernamePlaceholder")}
                  variant="outlined"
                  type={"text"}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                {error === usernameErrorMessage && (
                  <FormHelperText id="username-error-text">
                    {error}
                  </FormHelperText>
                )}
                <TextField
                  aria-describedby="password-error-text"
                  color={error === t("passwordErrorMessage") && "error"}
                  label={t("passwordPlaceholder")}
                  variant="outlined"
                  type={"password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                {error === passwordErrorMessage && (
                  <FormHelperText id="password-error-text">
                    {error}
                  </FormHelperText>
                )}
              </Stack>
              {/* error in credentials */}
              {/* {error && <Typography variant="body1" color="error">{error}</Typography>} */}
            </FormControl>
            <Button type="submit" variant="contained" disabled={loading}>
              {t("loginButtonText")}
            </Button>
          </Stack>
        </Form>
      </Container>
    </React.Fragment>
  );
}
