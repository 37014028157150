import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import * as React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookGridPage from "./BookGridPage";
import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function InventoryPage() {
  let { id } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = React.useState(() => [
    "available",
    "loaned",
    "maintenance",
  ]);
  const [loaner, setLoaner] = useState("");
  const frenchFormat = "dd/MM/yyyy";
  const today = new Date();
  const navigate = useNavigate();

  const [gridView, setGridView] = useState(false);

  const handleGridView = () => {
    if (!gridView) {
      setGridView(!gridView);
    }
  }

  const handleListView = () => {
    if (gridView) {
      setGridView(!gridView);
    }
  }

  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);

  
  const [startDeadlineDate, setStartDeadlineDate] = useState(null);
  const [endDeadlineDate, setEndDeadlineDate] = useState(null);

  const [visibleBorrow, setVisibleBorrow] = useState(true);
  const [visibleDeadline, setVisibleDeadline] = useState(true);
  const [visibleLoaner, setVisibleLoaner] = useState(true);

  const toggleDatePickerDeadlineVisibility = () => {
    setVisibleDeadline(!visibleDeadline);
  };

  const toggleDatePickerBorrowVisibility = () => {
    setVisibleBorrow(!visibleBorrow);
  };

  const toggleLoanerVisibility = () => {
    setVisibleLoaner(!visibleLoaner);
  };

  // Mobile compatibility
  const [isMobile, setIsMobile] = React.useState(false);

  const handleResize = () => {
    console.log(window)
    setIsMobile(typeof window !== 'undefined' && (window.innerWidth < 1200));
    //console.log("RESIZE ", isMobile);
  };

  const handleClear = () => {
    navigate(`/`);
  };

  const handleSubmit = (event) => {
    console.log(event.target.value);
    setLoaner(event.target.value);
  };

  const handleStartDateChange = (dateString) => {
    const date = new Date(dateString);
    console.log(date);
    setStartDate(date);
  };

  const handleEndDateChange = (dateString) => {
    const date = new Date(dateString);
    console.log(date);
    setEndDate(date);
  };

  const handleStartDeadlineDateChange = (dateString) => {
    const date = new Date(dateString);
    console.log(date);
    setStartDeadlineDate(date);
  };

  const handleEndDeadlineDateChange = (dateString) => {
    const date = new Date(dateString);
    console.log(date);
    setEndDeadlineDate(date);
  };

  const handleStatus = (event, newStatus) => {
    console.log("NEW CHOICE ", newStatus);
    setStatus(newStatus);
  };

  React.useEffect(() => {
    handleResize();
    setSearchInput(id);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [id, status, startDate, endDate, loaner, startDeadlineDate, endDeadlineDate]);

  return (
    <Box 
      display="flex"
    >
      
      {!isMobile && <Box
        maxHeight="82vh"
        position="sticky"
        top="13vh"
        sx={{
          flex: 18,
          marginLeft: "1vw",
          paddingLeft: "1%",
          paddingRight: "2%",
          paddingTop: "1%",
          zIndex: "2",
          // Debugging
          //backgroundColor: "blue",
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          gap: "2vh",
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "0.5vw",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "black", // Change thumb color
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "5px",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
          }}
        >
          <b>Status</b>
        </Typography>

        <ToggleButtonGroup
          color="primary"
          orientation="vertical"
          value={status}
          onChange={handleStatus}
          aria-label="text formatting"
        >
          <ToggleButton value="available" aria-label="available">
            <Typography fontSize={12}>Available</Typography>
          </ToggleButton>

          <ToggleButton value="loaned" aria-label="loaned">
            <Typography fontSize={12}>Loaned</Typography>
          </ToggleButton>

          <ToggleButton value="maintenance" aria-label="maintenance">
            <Typography fontSize={12}>Maintenance</Typography>
          </ToggleButton>
        </ToggleButtonGroup>

        <div>
      <Typography
        sx={{
          fontSize: "20px",
          display: "flex", 
          justifyContent: "space-between"
        }}
      >
        <b> Loan Date</b>
        <span
          style={{ cursor: "pointer"}}
          onClick={toggleDatePickerBorrowVisibility}
        >
          {visibleBorrow ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </span>
      </Typography>

      <div
        style={{
          overflow: "hidden",
          transition: "max-height 0.3s ease, opacity 0.3s ease",
          paddingTop: "1vh",
          maxHeight: visibleBorrow ? "500px" : "0",
          opacity: visibleBorrow ? "1" : "0",
          display: "flex", 
          flexDirection: "column", 
          gap: "2vh", 
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DatePicker
            label="Start Range"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
            maxDate={endDate}
            format={frenchFormat}
          />
          <DatePicker
            label="End Range"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} />}
            minDate={startDate}
            format={frenchFormat}
          />
        </LocalizationProvider>
      </div>
    </div>

        <div>
      <Typography
        sx={{
          fontSize: "20px",
          display: "flex", 
          justifyContent: "space-between",
        }}
      >
        <b> Deadline Date</b>
        <span
          style={{ cursor: "pointer" }}
          onClick={toggleDatePickerDeadlineVisibility}
        >
          {visibleDeadline ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </span>
      </Typography>

      <div
        style={{
          overflow: "hidden",
          transition: "max-height 0.3s ease, opacity 0.3s ease",
          paddingTop: "1vh",
          maxHeight: visibleDeadline ? "1000px" : "0",
          opacity: visibleDeadline ? "1" : "0",
          display: "flex", 
          flexDirection: "column", 
          gap: "2vh",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
          <DatePicker
            label="Start Range"
            value={startDeadlineDate}
            onChange={handleStartDeadlineDateChange}
            renderInput={(params) => <TextField {...params} />}
            maxDate={endDeadlineDate}
            format={frenchFormat}
          />
          <DatePicker
            label="End Range"
            value={endDeadlineDate}
            onChange={handleEndDeadlineDateChange}
            renderInput={(params) => <TextField {...params} />}
            minDate={startDeadlineDate}
            format={frenchFormat}
          />
        </LocalizationProvider>
      </div>
    </div>

        <Typography
            sx={{
              fontSize: "20px",
              display: "flex", 
              justifyContent: "space-between"
            }}
          >
            <b>Loaner</b>
            <span
          style={{ cursor: "pointer" }}
          onClick={toggleLoanerVisibility}
        >
          {visibleLoaner ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </span>
        </Typography>

        <div
        style={{
          transition: "max-height 0.3s ease, opacity 0.3s ease",
          maxHeight: visibleLoaner ? "500px" : "0",
          opacity: visibleLoaner ? "1" : "0",
        }}
      >

        <form onChange={handleSubmit}>
          
          <TextField
            placeholder="Search author by mail or phone"
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: "grey.200",
              borderRadius: "10",
            }}
          >
            <SearchIcon style={{ color: "secondary" }} />
          </TextField>
        </form>
        </div>

        <Button
          onClick={handleClear}
          style={{
            fontSize: "16px",
            textAlign: "left",
            textDecoration: "underline",
            textTransform: "none", // to keep the text case as is
            boxShadow: "none", // to remove the outer line or shadow
          }}
        >
          Clear
        </Button>
      </Box>}

      <Box
        sx={{
          flex: 80,
          top: "13vh",
          paddingTop: "1%",
          paddingBottom: "2%",
          //backgroundColor: "green",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "2vh",
          zIndex: "0",
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          gap="2vw"
        >
          {isMobile && <ToggleButtonGroup
          color="primary"
          orientation="horizontal"
          value={status}
          onChange={handleStatus}
          aria-label="text formatting"
        >
          <ToggleButton value="available" aria-label="available">
            <Typography fontSize={10}>Available</Typography>
          </ToggleButton>

          <ToggleButton value="loaned" aria-label="loaned">
            <Typography fontSize={10}>Loaned</Typography>
          </ToggleButton>

          <ToggleButton value="maintenance" aria-label="maintenance">
            <Typography fontSize={10}>Maintenance</Typography>
          </ToggleButton>
      </ToggleButtonGroup> }
        {/* <IconButton color="primary" onClick={handleListView}>
          {!gridView ? <ViewListIcon/> : <ViewListOutlinedIcon/>}
        </IconButton>

        <IconButton color="primary" onClick={handleGridView}>
          {gridView ? <GridViewRoundedIcon/> : <GridViewIcon/>}
          
        </IconButton>  */}
          
        </Box>

        <BookGridPage
          searchInput={searchInput}
          statusInput={status}
          startRangeLoanDate={startDate}
          endRangeLoanDate={endDate}
          startRangeDeadlineDate={startDeadlineDate}
          endRangeDeadlineDate={endDeadlineDate}
          loaner={loaner}
          gridView={gridView}
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
}
