import { Container } from "@mui/system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorBox from "../components/ErrorBox";
import LoadingBox from "../components/LoadingBox";
import SuccessBox from "../components/SuccessBox";
import { ServerOperations } from "../db/ServerOperations";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';

export default function ExtensionConfirmation() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(true);
  const [confirmationResult, setConfirmationResult] = React.useState({
    success: false,
    message: "",
  });
  const [confirmationCompleted, setConfirmationCompleted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    navigate('/');
  };

  const handleSendConfirmation = async () => {
    try {
      const response = await ServerOperations.requestLoanExtension(
        params.id.toString()
      );
      if (!response.ok) {
        let message = await response.text()
        setConfirmationResult({
          success: false,
          message: message,
        });
      } else {
        setConfirmationResult({
          success: true,
          message: t("requestLoanExtensionSuccessMessage"),
        });
      }
    } catch (error) {
      setConfirmationResult({
        success: false,
        message: t("requestLoanExtensionErrorMessage"),
      });
    } finally {
      setLoading(false);
      setConfirmationCompleted(true);
      setOpenDialog(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingBox />
      ) : (
        <Container
          sx={{
            position: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "400px",
            maxWidth: "90%",
            height: "auto",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>{t("loanExtensionTitle")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("loanExtensionDialog")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              <Button onClick={handleSendConfirmation}>{t("continue")}</Button>
            </DialogActions>
          </Dialog>
          
          {confirmationCompleted && (
            confirmationResult.success ? (
              <SuccessBox successMessage={confirmationResult.message} />
            ) : (
              <ErrorBox errorMessage={confirmationResult.message} />
            )
          )}
        </Container>
      )}
    </React.Fragment>
  );
}
