import { isbn } from "@form-validation/validator-isbn";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { Container } from "@mui/system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ErrorBox from "../components/ErrorBox";
import LoadingBox from "../components/LoadingBox";
import SuccessBox from "../components/SuccessBox";
import BookGridItem from "../components/list_item_layouts/BookGridItem";
import { ServerOperations } from "../db/ServerOperations";
import { useAuthContext } from "../hooks/useAuthContext";

import {
  Box,
  Button,
  Typography
} from "@mui/material";

import { Form } from "./Form.js";
import { set } from "date-fns";

export default function AddBookPage() {
  // Translation context
  const { t, i18n } = useTranslation();
  const auth = useAuthContext();
  const fallbackImageSrc = "./default_book_cover.jpeg";

  const [isMobile, setIsMobile] = React.useState(false);

  const handleResize = () => {
    setIsMobile(typeof window !== 'undefined' && (window.innerWidth < 1000));
  };

  const [isbn_val, setIsbn] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [cover, setCover] = React.useState(fallbackImageSrc);
  const [tag, setTag] = React.useState("");

  const [bookData, setBookData] = React.useState({});

  const [isValidIsbn, setIsValidIsbn] = React.useState(false);
  const [isFoundIsbn, setIsFoundIsbn] = React.useState(true);
  const [isValidTitle, setIsValidTitle] = React.useState(false);
  const [isValidAuthor, setIsValidAuthor] = React.useState(false);
  const [isValidTag, setIsValidTag] = React.useState(false);
  const isValidInput =
    isValidIsbn && isValidTitle && isValidAuthor && isValidTag;

  const [loading, setLoading] = React.useState(false);

  // Dialog setup
  const [dialogReturned, setDialogReturned] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [confirmationResult, setConfirmationResult] = React.useState({
    success: false,
    message: "",
  });

  const [confirmationCompleted, setConfirmationCompleted] = React.useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  // Buttons handlers
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handlePreview = async () => {
    try {
      //setLoading(true);
      const response = await ServerOperations.addBook(
        auth,
        tag,
        isbn_val,
        title,
        author,
        true,
        bookData
      );
      //setLoading(false);
      if (!response.ok) {
        console.log("NOT OK");
        let message = await response.text();
        console.log(message);
        setIsFoundIsbn(false);
      } else {
        let response_text = await response.text();
        let data = JSON.parse(response_text);
        //console.log("PREVIEW RETURNED", data)
        //console.log(data['title'], data['authors'][0], data['covers']['large']);
        // check if the attributes are present

        if (!data["title"] || !data["authors"] || (data["authors"].length == 0) || (!data["isbn_10"] && !data["isbn"])) {
            setIsFoundIsbn(false);
            console.log("NO TITLE OR AUTHOR");
        } else {
            setIsbn(data["isbn"] || data["isbn_10"]);
            setIsValidIsbn(true);
            setTitle(data["title"]);
            setIsValidTitle(true);
            handleAuthor({ target: { value: data["authors"][0]["name"] } });
            setBookData(data);
            let cover = data["covers"];
            if (cover !== "N/A") setCover(cover["large"]);
            else setCover(fallbackImageSrc);
        }
        }
    } catch (error) {
    } finally {
    }
  };

  const handleSubmit = async () => {
    try {
      //console.log("BOOK DATA", bookData)
      const response = await ServerOperations.addBook(
        auth,
        tag,
        isbn_val,
        title,
        author,
        false,
        bookData
      );
      if (!response.ok) {
        let message = await response.text();
        setConfirmationResult({
          success: false,
          message: message,
        });
        setDialogReturned(true);
        //handleClear();
      } else {
        setDialogReturned(true);
        setConfirmationResult({
          success: true,
          message: t("addBookSuccessMessage"),
        });
      }
    } catch (error) {
      setDialogReturned(true);
      setConfirmationResult({
        success: false,
        message: t("addBookRequestError"),
      });
    } finally {
      //setLoading(false);
      setDialogReturned(true);
      setConfirmationCompleted(true);
      setOpenDialog(false);
    }
  };

  const handleClear = () => {
    window.location.href = "/add-book";
  };

  // Form event handlers
  const handleIsbn = (event) => {
    setIsFoundIsbn(true);
    setBookData({ author: { id: "", name: author }, title: title });
    setCover(fallbackImageSrc);
    setIsbn(event.target.value);
    const result = isbn().validate({
      value: event.target.value,
      options: {
        message: "Please enter a valid ISBN",
      },
    });

    setIsValidIsbn(result.valid && event.target.value.length > 0);
    let updatedValue = {};
    updatedValue = { isbn: event.target.value };
    setBookData((bookData) => ({
      ...bookData,
      ...updatedValue,
    }));
  };

  const handleAuthor = (event) => {
    const validInput =
      event.target.value.length > 0 && event.target.value.length < 30;
    setIsValidAuthor(validInput);
    if (event.target.value.length < 30) {
      setAuthor(event.target.value);
      let updatedValue = {};
      updatedValue = { author: { id: "", name: event.target.value } };
      setBookData((bookData) => ({
        ...bookData,
        ...updatedValue,
      }));
    }
  };

  const handleTitle = (event) => {
    const validInput =
      event.target.value.length > 0 && event.target.value.length < 80;
    setIsValidTitle(validInput);
    if (event.target.value.length < 80) {
      setTitle(event.target.value);
      let updatedValue = {};
      updatedValue = { title: event.target.value };
      setBookData((bookData) => ({
        ...bookData,
        ...updatedValue,
      }));
    }
  };

  const handleTag = (event) => {
    setTag(event.target.value);
    // TODO: dynamic check (once tag are registered upon printing)
    setIsValidTag(event.target.value.length > 0);
  };

  React.useEffect(() => {
    // Initial check on component mount
    handleResize();

    // Event listeners
    window.addEventListener('resize', handleResize);
    

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column": "row",
            justifyContent: "space-around",
            maxWidth: "90%",
            height: "auto",
            marginTop: 0,
            backgroundColor: "grey.100",
            elevation: 2,
          }}
        >
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>
              {isValidInput
                ? t("addBookRequestTitle")
                : t("addBookInvalidRequestTitle")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {isValidInput ? t("addBookDialog") : t("addBookInvalidDialog")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t("cancel")}</Button>
              {isValidInput && (
                <Button onClick={handleSubmit}>{t("continue")}</Button>
              )}
            </DialogActions>
          </Dialog>

          {dialogReturned ? (
            <>
              {confirmationCompleted &&
                (confirmationResult.success ? (
                  <SuccessBox successMessage={confirmationResult.message} />
                ) : (
                  <ErrorBox errorMessage={confirmationResult.message} />
                ))}
            </>
          ) : (
            <>
              <Box
                sx={{
                  flex: 3,
                  display: "flex",
                  flexDirection: "column",
                  margin: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                  }}
                >
                  <b>Form</b>
                </Typography>

                <Form
                  title="ISBN"
                  handleFormContent={handleIsbn}
                  required={true}
                  placeholder={"ISBN-10 | ISBN-13"}
                  isValid={isValidIsbn}
                  value={isbn_val}
                  searchable={true}
                  handleSearch={handlePreview}
                  isFound={isFoundIsbn}
                  helperText={
                    "Invalid ISBN format (eg. ISBN-10: 0553381687 / ISBN-13: 978-3-16-148410-0)"
                  }
                ></Form>

                <Form
                  title="Title"
                  handleFormContent={handleTitle}
                  placeholder={"Please use less than 80 characters"}
                  required={true}
                  isValid={isValidTitle}
                  value={title}
                  isFound={isFoundIsbn}
                  helperText={"Invalid Title"}
                ></Form>

                <Form
                  title="Author"
                  handleFormContent={handleAuthor}
                  placeholder={"Please use less than 40 characters"}
                  required={true}
                  isValid={isValidAuthor}
                  value={author}
                  isFound={isFoundIsbn}
                  helperText={"Invalid Author"}
                ></Form>

                <Form
                  title="Tag ID"
                  placeholder={"Number under the QRCode and after the # on the physical tag"}
                  handleFormContent={handleTag}
                  required={true}
                  isValid={isValidTag}
                  value={tag}
                  isFound={true}
                  helperText={"Invalid Tag"}
                ></Form>

                
              </Box>

              { <Box
                sx={{
                  flex: 2,
                  margin: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                  }}
                >
                  <b>Preview</b>
                </Typography>

                <BookGridItem
                  key={""}
                  id={""}
                  imgUrl={cover}
                  bookTitle={title}
                  authors={[author]}
                  bookLoanStatusString={"availableLoan"}
                  divider={false}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    margin: 5,
                  }}
                >
                  <Button
                    onClick={handleOpenDialog}
                    color="primary"
                    variant="contained"
                    style={{
                      fontSize: "20px",
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>

                  <Button
                    onClick={handleClear}
                    style={{
                      fontSize: "20px",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    Clear
                  </Button>
                </Box>
              </Box> }
            </>
          )}
        </Container>
      )}
    </>
  );
}
