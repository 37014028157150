import { Container } from "@mui/system";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import ErrorBox from "./components/ErrorBox";
import { AuthContextProvider } from "./contexts/AuthContext";
import { useAuthContext } from "./hooks/useAuthContext";
import "./i18n.js";
import "./index.css";
import { routingErrorMessage } from "./lang/constants";
import reportWebVitals from "./reportWebVitals";
import BookInfoPage from "./views/BookInfoPage";
import BorrowReturnPage from "./views/BorrowReturnPage";
import ExtensionConfirmation from "./views/ExtensionConfirmation";
import InventoryPage from "./views/InventoryPage";
import LoginPage, { action as loginAction } from "./views/LoginPage";
import AddBookPage from "./views/AddBookPage";

// Create a new QueryClient instance
const queryClient = new QueryClient();

function Routes() {
  const auth = useAuthContext();

  function createRouter(auth) {
    const router = createBrowserRouter(
      createRoutesFromElements(
        <Route path="/" element={<App />} errorElement={<ErrorBoundary />}>
          <Route
            index
            action={loginAction}
            element={
              auth && auth.username ? <Navigate to="search" /> : <LoginPage />
            }
          />
          <Route
            path="books"
            element={
              auth && auth.username ? <InventoryPage /> : <Navigate to="/" />
            }
          />
          <Route
            path="search"
            element={
              auth && auth.username ? <InventoryPage /> : <Navigate to="/" />
            }
          />
          <Route
            path="search/:id"
            element={
              auth && auth.username ? <InventoryPage /> : <Navigate to="/" />
            }
          />
          <Route path="manage-book/:id" element={<BookInfoPage />} />
          <Route path="book/:id" element={<BorrowReturnPage />} />
          <Route path="add-book" element={<AddBookPage />} />
          <Route 
            path="book/:id/request-extension" 
            element={<ExtensionConfirmation />}/>
        </Route>
      )
    );
    return router;
  }

  const router = createRouter(auth);

  return <RouterProvider router={router} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function ErrorBoundary() {
  // let error =
  // console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <React.Fragment>
      {
        <Container
          sx={{
            position: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "400px",
            maxWidth: "90%",
            height: "auto",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <ErrorBox errorMessage={routingErrorMessage} />
        </Container>
      }
    </React.Fragment>
  );
}
