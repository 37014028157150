import { Author } from "./Author"
import { Borrower } from "./Borrower"
import { BookStatus } from "./enums/BookStatus.js"


export class Book {
    private id: string
    private uuid: string
    private title: string
    private description: string
    private isbn: string
    private library: string;
    private status: string | undefined
    private loan_date: string | undefined
    private deadline_date: string | undefined
    private covers: Array<string>
    private authors: Array<Author> = [];
    private borrower: Borrower | undefined;

    constructor(id: string, uuid: string, title: string, description: string, isbn: string, library: string, covers: Array<string> = []) {
        this.id = id
        this.uuid = uuid
        this.title = title
        this.description = description
        this.isbn = isbn
        this.library = library
        this.covers = covers
    }

    public static empty(): Book {
        return new Book('', '', '', '', '', '')
    }

    // Create a new book instance from a parsed JSON response
    public static fromResponse(response: any): Book | undefined {
        /*
        if (response['title']=="A Game of Thrones" && response.library_infos.hasOwnProperty('name')) {
            console.log("SISIONSIDNC", response)
        }*/
        
        // Populating a book
        if (response !== undefined
            && response.hasOwnProperty('_id')
            && response.hasOwnProperty('uuid')
            && response.hasOwnProperty('title')
            && response.hasOwnProperty('description')
            && response.hasOwnProperty('isbn')
            && response.hasOwnProperty('library_infos')
            && response.library_infos.hasOwnProperty('name')
        ) {
            let book = new Book(
                response._id,
                response.uuid,
                response.title,
                response.summary,
                response.isbn,
                response.library_infos.name,
            )

            // Adding covers infos
            if (response.hasOwnProperty('covers')) {
                book.setCovers(response.covers);
            }

            // Adding loan infos
            if (response.hasOwnProperty('book_status')
                && response.book_status.hasOwnProperty('status')
                && response.book_status.hasOwnProperty('loan_date')
                && response.book_status.hasOwnProperty('deadline_date')
            ) {
                book.setStatus(response.book_status.status)
                book.setLoanDate(response.book_status.loan_date)
                book.setDeadlineDate(response.book_status.deadline_date)
            }

            // Adding Author's infos
            book.setAuthors(Author.fromResponse(response))

            // Adding Borrower's infos
            book.setBorrower(Borrower.fromResponse(response))

            return book
        } else {
            return undefined
        }
    }

    // Book fields setters & getters
    private setStatus(status: string): void {
        this.status = status
    }
    
    private setLoanDate(loan_date: string) {
        this.loan_date = loan_date
    }

    private setDeadlineDate(deadline_date: string) {
        this.deadline_date = deadline_date
    }

    public getId(): string {
        return this.id
    }
    public getUUID(): string {
        return this.uuid
    }
    public getTitle(): string {
        return this.title
    }
    public getSummary(): string {
        return this.description
    }
    public getISBN(): string {
        return this.isbn
    }
    public getLibrary(): string {
        return this.library
    }

    // Returns state to enable/disable buttons on UI
    public isAvailable(): boolean {
        return this.status === BookStatus.Available || this.status === undefined
    }
    public isInMaintenance(): boolean {
        return this.status === BookStatus.Maintenance
    }
    public isLoaned(): boolean {
        return this.status === BookStatus.Loaned
    }
    public canBeDeleted(): boolean {
        return this.isInMaintenance() || this.isAvailable()
    }

    public getStatus(): string {
        switch (this.status) {
            case BookStatus.Loaned: return 'loaned';
            case BookStatus.Maintenance: return 'maintenance';
            default : return 'available';
        }
    }

    // Outputs a string with the infos of the borrower
    public getLoanStatusString(showLoaner: boolean = true): string {
        switch (this.status) {
            case BookStatus.Loaned: return (showLoaner ? (this.borrower !== undefined ? 'loanedBy' : 'loaned'): 'loaned');
            case BookStatus.Maintenance: return 'maintenance';
            default : return 'availableLoan';
        }
    }

    public getLoanerString(): string {
        switch (this.status) {
            case BookStatus.Loaned: return (this.borrower !== undefined ? this.borrower.toString() : '-');
            default : return "";
        }
    }

    // Outputs a string with the loan date info
    public getLoanDate(hours: boolean = true): string {
        let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        var date = this.loan_date !== undefined ? new Date(this.loan_date) : undefined
        var date_str = date !== undefined ? (hours ? date.toLocaleString(): date.toLocaleDateString('fr', options)) : '-'
        return date_str
    }

    public getDeadlineDate(hours : boolean = true): string {
        let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        var date = this.deadline_date !== undefined ? new Date(this.deadline_date) : undefined
        var date_str = date !== undefined ? (hours ? date.toLocaleString(): date.toLocaleDateString('fr', options))  : '-'
        return date_str
    }

    // Author setters/getters
    private setAuthors(authors: Array<Author>): void {
        this.authors = authors
    }
    public getAuthors(): Array<Author> {
        return this.authors
    }

    // Borrower setters/getters
    private setBorrower(borrower: Borrower | undefined): void {
        this.borrower = borrower
    }
    public getBorrower(): Borrower | undefined {
        return this.borrower
    }

    public getCover(): string {
        if (this.covers.length > 0) {
            return this.covers[0];
        } else {
            return process.env.PUBLIC_URL + '/default_book_cover.jpeg';
        }

    }

    private setCovers(covers: Array<string>): void {
        this.covers = covers;
    }
}
