export class Author {
    private acronym: string
    private name: string
    private date_of_birth: string
    private date_of_death: string

    constructor(acronym: string, name: string, date_of_birth: string, date_of_death: string) {
        this.acronym = acronym
        this.name = name
        this.date_of_birth = date_of_birth
        this.date_of_death = date_of_death
    }

    // Create new array of authors from a parsed JSON response
    public static fromResponse(response: any): Array<Author> {
        let authors: Array<Author> = []

        if (
            response.hasOwnProperty('author_infos')
        ) {
            authors = response.author_infos.filter(
                (author: any) => author.hasOwnProperty('acronym') && author.hasOwnProperty('name')
            ).map((author: any) => {
                return new Author(
                    author.acronym,
                    author.name,
                    '',
                    ''
                )
            })
        }
        //     && response.author_infos.hasOwnProperty('first_name')
        //     && response.author_infos.hasOwnProperty('family_name')
        //     && response.author_infos.hasOwnProperty('date_of_birth')
        //     && response.author_infos.hasOwnProperty('date_of_death')
        // ) {
        //     authors.push(
        //         new Author(
        //             response.author_infos.first_name,
        //             response.author_infos.family_name,
        //             response.author_infos.date_of_birth,
        //             response.author_infos.date_of_death,
        //         )
        //     )
        // }

        return authors
    }

    public getName(): string {
        return this.name
    }

    public getDates(): string {
        return `${this.date_of_birth} - ${this.date_of_death}`
    }
}