import { DialogContent, Typography } from "@mui/material";

const HelpDialogContent = () => {
  return (
    <DialogContent>
      <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
        How to use Unlost Bookfinder
      </Typography>

      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>Access the Admin portal for your library</b>
      </Typography>
      <Typography variant="body1" paragraph>
        You should have received either in a separate email, or in a physical
        paper hand-to-hand the login credentials of your library on the Unlost
        Bookfinder service. If you lost or forgot your credentials, please
        contact us at <a href="mailto:contact@unlost.ch">contact@unlost.ch</a>.
      </Typography>
      <Typography variant="body1" paragraph>
        Go to{" "}
        <a href="https://unlost.ch/" target="_blank" rel="noopener noreferrer">
          https://unlost.ch/
        </a>{" "}
        and enter your credentials. You should have access to all your books
        grouped in three categories:
      </Typography>

      <ul>
        <li>
          <b>Loaned:</b> These are books currently borrowed by a user.
          On the admin page, you have access to the user’s email and phone
          number.
        </li>
        <li>
          <b>Available:</b> These are books currently available in the
          library and available for any user to borrow.
        </li>
        <li>
          <b>In Maintenance:</b> These are books currently unavailable for users
          to borrow. This concerns books that have been ordered and registered
          by the library but not yet available in the physical library, or books
          that were damaged or lost.
        </li>
      </ul>

      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>How to put a book in Maintenance state?</b>
      </Typography>
      <Typography variant="body1" paragraph>
        You identified that the information displayed for a book is incorrect?
        You damaged the tag associated with the book? You do not want this book
        to be available for users to borrow?
      </Typography>
      <Typography variant="body1" paragraph>
        You can put an available book in maintenance state. To do so:
      </Typography>

      <ol>
        <li>Click on the book in your admin page</li>
        <li>Click on ‘Mark Unavailable’</li>
      </ol>

      {<Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>How to remind a user to return a book?</b>
      </Typography>}

      {<Typography variant="body1" paragraph>
        <ol>
          <li>When a user borrows a book, he is given a deadline of <b>3 weeks to return the book</b>.</li>
          <li><b>3 days before the deadline</b>, the user is automatically reminded by email to return the book.</li>
          <li>In addition, you can manually remind a user to return a book by clicking on the book in your admin page and then clicking on ‘Notify Borrower’.</li>
          <li>In a reminder, the users have the ability to ask for a <b>deadline extension of 3 weeks</b> if they are still reading the book.</li>
          <li>A user will be notified if he does not return the book after the deadline.</li>
        </ol>
      </Typography>}

      {<Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>How to add a book to Bookfinder ?</b>
      </Typography>}

      {<Typography variant="body1" paragraph>
        <ol>
          <li>You should have a book and an Unlost tag.</li>
          <li>Click on the "Add Book" button (on mobile, click on the drawer on the top left).</li>
          <li>Fill the ISBN of the book and click on 'AutoFill'.</li>
          <li>If the autofill does not find a corresponding book, fill the additional fields.</li>
          <li>Fill the tag ID field with the number located after the '#' on your Unlost tag.</li>
          <li>Click 'Submit'.</li>
        </ol>
      </Typography>}
      

      {/* <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>How do I register a book to Unlost Bookfinder?</b>
      </Typography>
      <Typography variant="body1" paragraph>
        Depending on the initial plan you chose, you may have access to a number
        of ‘Unassigned tags’. These tags are available for you to attach them to
        any book or object you want your users to borrow.
      </Typography>
      <Typography variant="body1" paragraph>
        If you have a book and any tag, you can add it by following the steps
        below:
      </Typography>

      <ol>
        <li>
          Go to{" "}
          <a
            href="https://forms.gle/L5TXdSg95XB5K4T2A"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://forms.gle/L5TXdSg95XB5K4T2A
          </a>
        </li>
        <li>Enter the tag’s number</li>
        <li>Enter the book’s ISBN or ‘N/A’ if it does not have one</li>
        <li>Enter the book’s title</li>
        <li>Enter the book’s author</li>
        <li>Submit</li>
      </ol>

      <Typography variant="body1" paragraph>
        You should see the book added to the service in the next few days
        following your submission (usually within 24h).
      </Typography> */}

      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        <b>
          I would like to add a new feature to Unlost Bookfinder, how can I do
          that?
        </b>
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        Your feedback is very valuable. Please give us any feedback{" "}
        <a
          href="https://forms.gle/eGF4cvu9aBmJ8hat6"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </Typography>
    </DialogContent>
  );
};

export default HelpDialogContent;
