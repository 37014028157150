import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BookCard from '../components/BookCard';
import ErrorBox from '../components/ErrorBox';
import LoadingBox from '../components/LoadingBox';
import { ServerOperations } from '../db/ServerOperations';
import { useAuthContext } from '../hooks/useAuthContext';
import { notFoundErrorMessage } from '../lang/constants';
import { Book } from '../models/Book';

export default function BorrowReturnPage() {
    const {t} = useTranslation();
    // Fetching the desired book
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    const [book, setBook] = React.useState(Book.empty());
    const [errorMessage, setErrorMessage] = React.useState(t('networkErrorMessage'));

    const auth = useAuthContext();

    // TODO: FETCH BOOK COVER FROM A BOOK API
    React.useEffect(() => {
        const asyncFetchBook = async () => {
            const book = await ServerOperations.getBook(params.id.toString())
            if (book === undefined) {
                setErrorMessage(notFoundErrorMessage)
            }

            setBook(book)
            setLoading(false)
        }
        asyncFetchBook();
        
        return;
    }, [params.id, navigate]);

    // Handle opening/closing alerts
    const [openUnavailable, setOpenUnavailable] = React.useState(false)
    const [openAvailable, setOpenAvailable] = React.useState(false)
    const [openNotifyEmail, setOpenNotifyEmail] = React.useState(false)
    const [openDelete, setOpenDelete] = React.useState(false)

    const handleClickOpenUnavailable = () => {
        setOpenUnavailable(true)
    }

    const handleClickOpenAvailable = () => {
        setOpenAvailable(true)
    }

    const handleMarkAsUnavailable = async () => {
        if (auth && auth.username) {
            const res = await ServerOperations.setBookAsUnAvailable(book.getUUID(), auth);
            if (res.ok) { 
                // refresh page
                window.location.reload();
            }
            handleClose();
        }
    }

    const handleMarkAsAvailable = async () => {
        if (auth && auth.username) {
            const res = await ServerOperations.setBookAsAvailable(book.getUUID(), auth, null);
            if (res.ok) { 
                // refresh page
                window.location.reload();
            }
            handleClose();
        }
    }

    const handleNotifyByEmail = async () => {
        if (auth && auth.username) {
            const res = await ServerOperations.notifyBorrower(book.getUUID(), auth, {email : book.getBorrower().getEmail(), library: book.getLibrary()});
            if (res.ok) {
                setSnackbarMessage(t('notifyBorrowerText'));
                setOpenSnackBarSuccess(true);
            } else {
                setSnackbarMessage("");
                setOpenSnackBarFail(true);
            }
            handleClose();
        }
    }

    const handleDelete = async () => {
        if (auth && auth.username) {
            const res = await ServerOperations.deleteBook(book.getUUID(), auth);
            if (res.ok) {
                setSnackbarMessage("The book has been succesfully deleted");
                setOpenSnackBarSuccess(true);
            } else {
                setSnackbarMessage("");
                setOpenSnackBarFail(true);
            }
            handleClose();
        }
    }

    const handleClose = () => {
        setOpenUnavailable(false);
        setOpenAvailable(false);
        setOpenNotifyEmail(false);
        setOpenDelete(false);
    }

    const [openSnackBarSuccess, setOpenSnackBarSuccess] = React.useState(false)
    const [openSnackBarFail, setOpenSnackBarFail] = React.useState(false)
    const [openSnackBarNA, setOpenSnackBarNA] = React.useState(false)
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const handleClickNotifyBorrower = () => {
        setOpenNotifyEmail(true);
        // setOpenSnackBarNA(true)
    }

    const handleClickDelete = () => {
        setOpenDelete(true);
        // setOpenSnackBarNA(true)
    }

    const handleSnackBarSuccessClose = () => {
        setOpenSnackBarSuccess(false)
    }
    const handleSnackBarFailClose = () => {
        setOpenSnackBarFail(false)
    }
    const handleSnackBarNAClose = () => {
        setOpenSnackBarNA(false)
    }

    return (
        <React.Fragment>
            {
                loading
                    ? <LoadingBox />
                    : (
                        <Container
                            sx={{
                                position: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '400px',
                                maxWidth: '90%',
                                height: "auto",
                                //maxHeight: '30vh',
                                marginTop: 0,
                                marginBottom: 5,
                            }}
                        >
                            {
                                book === undefined
                                    ? <ErrorBox errorMessage={errorMessage} />
                                    : ( (auth && auth.username) ? (
                                        //console.log(book) &&
                                        <Stack spacing={1} >
                                            <BookCard 
                                                imgUrl={book.getCover()}
                                                authorNames={book.getAuthors().map(author => author.getName())}
                                                bookTitle={book.getTitle()}
                                                bookLoanStatusString={book.getLoanStatusString(false)}
                                                bookLoaner={(auth && auth.username) ? book.getLoanerString(): ""}
                                                isBookLoaned={book.isLoaned()}
                                                bookLoanDate={t('loanDate')+book.getLoanDate()}
                                                bookDeadlineDate={"Deadline : "+book.getDeadlineDate(false)}
                                                showLoaner={(auth && auth.username) ? true : false}
                                            />

                                            <Button type="submit" variant="outlined" startIcon={<SendIcon />} onClick={handleClickNotifyBorrower} disabled={!auth || !auth.username || book.isAvailable()}>{t('notifyBorrower')}</Button>
                                            {
                                                book.isInMaintenance() ? (
                                                    <Button type="submit" variant="contained" style={{backgroundColor: '#228B22',}} startIcon={<CheckBoxIcon />} onClick={handleClickOpenAvailable} disabled={!auth || !auth.username || !book.isInMaintenance()}> {t('bookAvailable')} </Button>
                                                ) : (
                                                    <Button type="submit" variant="contained" color="warning" startIcon={<EngineeringIcon />} onClick={handleClickOpenUnavailable} disabled={!auth || !auth.username || !book.canBeDeleted() || book.isInMaintenance()}>{t('bookUnavailable')}</Button>
                                                )
                                            }
                                            {/*<Button type="submit" variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleClickDelete} disabled={!auth || !auth.username || book.isLoaned()}>{"Delete Book"}</Button>*/}
                                        </Stack>
                                    ) : (
                                        <ErrorBox errorMessage={t('routingErrorMessage')} />
                                    ))
                            }
                        </Container>
                    )
            }

            <Dialog open={openUnavailable} onClose={handleClose}>
                <DialogTitle>{t('bookUnavailableDialogTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    {t('bookUnavailableDialogTitle')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleMarkAsUnavailable}>{t('continue')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAvailable} onClose={handleClose}>
                <DialogTitle>{t('bookAvailableDialogTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('bookAvailableDialog')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleMarkAsAvailable}>{t('continue')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openNotifyEmail} onClose={handleClose}>
                <DialogTitle>{t("notifyMailDialog")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("notifyMailDialogConfirm")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleNotifyByEmail}>{t('continue')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDelete} onClose={handleClose}>
                <DialogTitle>{"Delete Book"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Do you confirm the deletion of the book ?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleDelete}>{t('continue')}</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackBarSuccess}
                autoHideDuration={5000}
                onClose={handleSnackBarSuccessClose}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label='close'
                        color='inherit'
                        onClick={handleSnackBarSuccessClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Snackbar
                open={openSnackBarFail}
                autoHideDuration={5000}
                onClose={handleSnackBarFailClose}
                message={t('notifyBorrowerFailText')}
                action={
                    <IconButton
                        size="small"
                        aria-label='close'
                        color='inherit'
                        backgroundColor='blue'
                        onClick={handleSnackBarFailClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />

            <Snackbar
                open={openSnackBarNA}
                autoHideDuration={5000}
                onClose={handleSnackBarNAClose}
                message={t('tempSnackBarText')}
                action={
                    <IconButton
                        size="small"
                        aria-label='close'
                        color='inherit'
                        backgroundColor='blue'
                        onClick={handleSnackBarNAClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </React.Fragment>
    )
}