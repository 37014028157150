import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Drawer,
  Paper,
  TextField,
  Button,
  Toolbar,
  Typography,
  IconButton
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import "../i18n";
import { AdditionalIconsSection } from "./header/AdditionalIcons";
import { DrawerContent } from "./header/DrawerContent";
import { HelpDialog } from "./header/HelpDialog";
import { LogoSection } from "./header/LogoSection";
import { SearchBar } from "./header/SearchBar";

const AppHeader = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [landscape, setLandscape] = React.useState(false);

  const handleResize = () => {
    setIsMobile(typeof window !== 'undefined' && (window.innerWidth < 1200) && (!landscape));
  };

  const handleOrientationChange = () => {
    // React to orientation change
    // setIsMobile(typeof window !== 'undefined' && (window.innerWidth < 1200) && (!landscape));
    setLandscape(!landscape);
    console.log("ORIENTATION CHANGE ", window.innerWidth);
  };

  // Search Bar 
  const [isSearchBarExpanded, setIsSearchBarExpanded] = React.useState(false);

  const toggleSearchBar = () => {
    setIsSearchBarExpanded(!isSearchBarExpanded);
  };

  const closeSearchBar = () => {
    setIsSearchBarExpanded(false);
  };

  const handleSearchBar = () => {
    if (!isSearchBarExpanded) {
      toggleSearchBar(!isSearchBarExpanded);
    }
  }

  const { t } = useTranslation();
  const { logout } = useLogout();
  const auth = useAuthContext();
  const [scrolling, setScrolling] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerNotifOpen, setDrawerNotifOpen] = React.useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const navigate = useNavigate();

  const searchQuery = (query) => {
    // TODO: SANITIZE INPUT
    const queryProcessed = query.toLowerCase();
    navigate(`/search/${queryProcessed}`);
  };

  const handleChange = (e) => {
    // Update the state with the current input value
    console.log("CHANGE ", e.target.value);
    searchQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const inputValue = e.target.elements[0].value; // Get the input value
    searchQuery(inputValue);
  };

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  };

  const handleScroll = () => {
    setScrolling(window.scrollY > 0);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerNotifOpen = () => {
    setDrawerNotifOpen(true);
  };

  const handleDrawerNotifClose = () => {
    setDrawerNotifOpen(false);
  };

  const handleOpenHelpDialog = () => {
    setHelpDialogOpen(true);
  };

  const handleCloseHelpDialog = () => {
    setHelpDialogOpen(false);
  };

  const handleTagsFormClick = () => {
    window.location.href = "/add-book";
  };

  const handleContactFormClick = () => {
    window.open("https://forms.gle/eGF4cvu9aBmJ8hat6", "_blank");
  };

  React.useEffect(() => {
    // Initial check on component mount
    handleResize();

    // Event listeners
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        elevation={scrolling ? 4 : 0}
        style={{ backgroundColor: "white", top: "0", zIndex: 1 }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "13vh",
          }}
        >
          <LogoSection handleDrawerOpen={handleDrawerOpen} isMobile={isMobile} isSearchBarExpanded={isSearchBarExpanded}/>

          {auth && auth.username && (
            <Box
              sx={{
                display: "flex",
                gap: "2vw",
                marginRight: "1vw",
                flex: 120,
              }}
            >
              {!isSearchBarExpanded && !isMobile && <>
              <Box
                component="div"
                sx={{
                  //flex: 25,
                  borderRadius: 10,
                  backgroundColor: "grey.200",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <LocationOnIcon color="primary" />
                <Typography
                  component="div"
                  color="primary"
                  sx={{
                    fontSize: "14px",
                    marginLeft: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {auth.name}
                </Typography>
              </Box>

              {
              <Box
                component="div"
                onClick={handleTagsFormClick}
                sx={{
                  //flex: 10,
                  borderRadius: 20,
                  backgroundColor: "black",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  paddingTop: "1%",
                  paddingBottom: "1%",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <BookmarkAddIcon style={{ color: "white" }} />
                <Typography
                  component="div"
                  sx={{
                    marginLeft: 1,
                    fontSize: "14px",
                    color: "white",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    color: "white",
                  }}
                >
                  <b>Add Book</b>
                </Typography>
                </Box>}
              
              </>}
              
              <SearchBar handleSubmit={handleSubmit} handleChange={handleChange} handleExpanded={handleSearchBar} closeSearchBar={closeSearchBar} isMobile={isMobile}/>
            </Box>
          )}

          {!isSearchBarExpanded && <AdditionalIconsSection
            handleOpenHelpDialog={handleOpenHelpDialog}
            handleDrawerNotifOpen={handleDrawerNotifOpen}
            isMobile={isMobile}
          />}

          {isSearchBarExpanded && (
            <IconButton color="primary" onClick={toggleSearchBar}>
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <DrawerContent
        handleContactFormClick={handleContactFormClick}
        handleDrawerClose={handleDrawerClose}
        handleTagsFormClick={handleTagsFormClick}
        handleLogout={handleLogout}
        drawerOpen={drawerOpen}
        isMobile={isMobile}
      />

      <HelpDialog
        handleCloseHelpDialog={handleCloseHelpDialog}
        helpDialogOpen={helpDialogOpen}
      />

      <Drawer
        anchor="right"
        open={drawerNotifOpen}
        onClose={handleDrawerNotifClose}
      >
        <Paper
          sx={{
            width: "30vw",
            height: "100%",
            marginTop: "2vh",
            display: "flex",
            flexDirection: "column",
            gap: "4vh",
          }}
        >
          <Typography fontSize="35px" textAlign="center">
            Pending Requests
          </Typography>
        </Paper>
      </Drawer>
    </>
  );
};

export default AppHeader;
