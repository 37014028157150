// Function to calculate font size based on title length
export const calculateFontSize = (title) => {
  const titleLength = title.length;
  if (titleLength > 20) {
    return "1rem"; // based on the title length
  } else {
    return "1.5rem"; // Default font size
  }
};

// Function to calculate font size based on title length
export const statusColor = (status) => {
  if (status === "availableLoan") {
    return "green";
  } else if (status === "maintenance") {
    return "orange";
  }

  return "red";
};
