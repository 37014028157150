import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BookIcon from "@mui/icons-material/Book";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import {
  Backdrop,
  Box,
  Divider,
  Drawer,
  Paper,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useAuthContext } from "../../hooks/useAuthContext";
import "../../i18n";

export const DrawerContent = ({
  handleDrawerClose,
  handleTagsFormClick,
  handleContactFormClick,
  handleLogout,
  drawerOpen,
  isMobile
}) => {
  const auth = useAuthContext();

  return (
    <>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <Paper
          sx={{
            width: isMobile ? "60vw": "22vw",
            height: "100%",
            marginTop: "2vh",
            display: "flex",
            flexDirection: "column",
            gap: "4vh",
          }}
        >
          {/* Account Display */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "6%",
            }}
          >
            <AccountCircleIcon sx={{ fontSize: 60 }} />
            <Typography component="div" sx={{ marginLeft: 1 }}>
              ADMIN
            </Typography>
          </Box>

          {/* Separator */}
          <Divider sx={{ marginX: "5%", backgroundColor: "lightgray" }} />

          {/* Account Gestion Button */}
          {/* <Box */}
            {/* sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%", */}
              {/* /** 
              "&:hover": {
                cursor: "pointer",
              },
              **/ }
            {/* }} */}
          {/* > */}
            {/* <ManageAccountsIcon sx={{ fontSize: 30 }} />
            <Typography component="div" sx={{ marginLeft: 1, color: 'lightgray' }}>
              <b> Manage Account </b>
            </Typography>
          </Box> */}

          {/* Separator */}
          {/* <Divider sx={{ marginX: "20%", backgroundColor: "lightgray" }} /> */}

          {/* Tag Gestion Button */}
          { /**
            <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleTagsFormClick}
          >
            <BookIcon sx={{ fontSize: 30 }} />
            <Typography component="div" sx={{ marginLeft: 1 }}>
              <b> Request Tags </b>
            </Typography>
          </Box>

          <Divider sx={{ marginX: "20%", backgroundColor: "lightgray" }} />
          **/
          }

          {/* Dark Mode Toggle */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%",
            }}
          >
            <DarkModeIcon sx={{ fontSize: 30 }} />
            <Typography component="div" sx={{ marginLeft: 1, color: 'lightgray' }}>
              <b> Dark Mode </b>
            </Typography>
            { */}
              {/* /**
               * <Switch
              sx={{
                "&.Mui-disabled": {
                  color: "darkgray", // Change to your desired dark color
                },
                alignItems: "right",
              }}
            />
               */
            // }
            
          // </Box>
        }

          {/* Separator */}
          {/* <Divider sx={{ marginX: "20%", backgroundColor: "lightgray" }} /> */}

          {/* Contact Form */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleContactFormClick}
          >
            <ContactSupportIcon sx={{ fontSize: 30 }} />
            <Typography component="div" sx={{ marginLeft: 1 }}>
              <b> Contact Form </b>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleTagsFormClick}
          >
            <BookmarkAddIcon sx={{ fontSize: 30 }} />
            <Typography component="div" sx={{ marginLeft: 1 }}>
              <b> Add a book </b>
            </Typography>
          </Box>

          {/* Separator */}
          <Divider sx={{ marginX: "20%", backgroundColor: "lightgray" }} />

          {/* Deconnect */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              marginLeft: "10%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <Typography
              component="div"
              onClick={handleLogout}
              sx={{ marginLeft: 0, color: "gray", fontWeight: "bold" }}
            >
              <b> Log Out </b>
            </Typography>
          </Box>
        </Paper>
      </Drawer>

      <Backdrop
        open={drawerOpen}
        onClick={handleDrawerClose}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
    </>
  );
};
