import { Box, List, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BookListItem from "./list_item_layouts/BookListItem";

export default function BookListBox(props) {
  const boxTitle = props.boxTitle;
  const elements = props.elements;
  const isMobile = props.isMobile;
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      <Stack
        sx={{
          width: "100%",
          border: 0,
          borderColor: "gray",
          borderRadius: 1,
          padding: 2,
        }}
      >
        {false && <Typography variant="h4">{boxTitle}</Typography>}

        {!elements.length ? (
          <Typography>{t("noBooksMessage")}</Typography>
        ) : (
          <List sx={{ width: "100%" }}>
            
            {elements.map((book) => (
              
              <BookListItem
                key={book.getUUID()} // Key is needed by React to differentiate elements from the list
                id={book.getUUID()}
                title={book.getTitle()}
                bookDesc={book.getLoanStatusString(false)}
                divider={false}
                imgUrl={book.getCover()}
                author={book.getAuthors().map(author => author.getName())}
                borrower={book.getBorrower()}
                isMobile={isMobile}
                loanDate={book.getLoanDate(false)}
                deadlineDate={book.getDeadlineDate(false)}
              />
            ))}
          </List>
        )}
      </Stack>
    </Box>
  );
}
