import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Stack, Typography } from "@mui/material";

export default function ErrorBox(props) {
  const errorMessage = props.errorMessage;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
      sx={{
        backgroundColor: "error.light",
        borderRadius: 2,
      }}
    >
      <Stack
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon
          fontSize="large"
          sx={{
            color: "white",
          }}
        />
        <Typography fontWeight="bold" color="white">
          Error
        </Typography>
        <Typography align="center" color="white">
          {errorMessage}
        </Typography>
      </Stack>
    </Box>
  );
}
