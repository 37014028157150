import { Box, Button, Container, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BookGrid from "../components/BookGrid";
import LoadingBox from "../components/LoadingBox";
import { ServerOperations } from "../db/ServerOperations";
import { useAuthContext } from "../hooks/useAuthContext";

import Lottie from "react-lottie";
import animationData from "../theme/pageTurn.json";
import BookListBox from "../components/BookListBox";

export default function BookGridPage(props) {
  // Translation context
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuthContext();

  // Fetch list of books
  const [loading, setLoading] = React.useState(true);
  const [allBooks, setAllBooks] = React.useState([]);
  const searchInput = props.searchInput;
  const statusInput = props.statusInput;
  const startRangeLoanDate = props.startRangeLoanDate;
  const endRangeLoanDate = props.endRangeLoanDate;
  const startRangeDeadlineDate = props.startRangeDeadlineDate;
  const endRangeDeadlineDate = props.endRangeDeadlineDate;

  const loaner = props.loaner;
  const gridView = false;
  const isMobile = props.isMobile;

  const filterLoanDate = (el) => {
    if (startRangeLoanDate == undefined || endRangeLoanDate == undefined) {
      return true;
    } else {
      let bookLoanDate = new Date(el["loan_date"]);
      let startRange = new Date(startRangeLoanDate);
      let endRange = new Date(endRangeLoanDate);

      return bookLoanDate >= startRange && bookLoanDate <= endRange;
    }
  };

  const filterDeadlineDate = (el) => {
    if (startRangeDeadlineDate == undefined || endRangeDeadlineDate == undefined) {
      return true;
    } else {
      let bookDeadlineDate = new Date(el["deadline_date"]);
      let startRange = new Date(startRangeDeadlineDate);
      let endRange = new Date(endRangeDeadlineDate);

      return bookDeadlineDate >= startRange && bookDeadlineDate <= endRange;
    }
  };

  const filterLoaner = (el) => {
    if (loaner === "" || loaner == undefined) {
      return true;
    } else {
      let bookLoaner = el.getLoanerString();
      return bookLoaner.includes(loaner);
    }
  };

  const filterTitle = (el) => {
    if (searchInput === "" || searchInput == undefined) {
      return true;
    } else {
      let searchTerm = searchInput.toLowerCase();
      return el.getTitle().toLowerCase().includes(searchTerm);
    }
  };

  const filterStatus = (el) => {
    if (statusInput == undefined) {
      return true;
    } else {
      return statusInput.includes(el.getStatus().toLowerCase());
    }
  };
  // Filter elements based on the search input
  const filtered = allBooks
    .filter((el) => filterTitle(el))
    .filter((el) => filterStatus(el))
    .filter((el) => filterLoanDate(el))
    .filter((el) => filterDeadlineDate(el))
    .filter((el) => filterLoaner(el));

  const handleDisplayAll = () => {
    navigate(`/`);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  React.useEffect(() => {
    const asyncFetchBooks = async (auth) => {
      const parsedBooks = await ServerOperations.getBooks(auth);
      setAllBooks(parsedBooks.filter((book) => book !== undefined));
      setLoading(false);
    };

    if (auth && auth.username) {
      asyncFetchBooks(auth);
    }

    return;
  }, [allBooks.length, auth]);

  return (
    <React.Fragment>
      {loading ? (
        <LoadingBox />
      ) : !filtered.length ? (
        <Box
          display="flex"
          gap="2vh"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Lottie options={defaultOptions} height={200} width={200} />

          <Typography>{t("noBooksMessage")}</Typography>

          <Button
            color="secondary"
            outline="secondary"
            onClick={handleDisplayAll}
            sx={{
              backgroundColor: "black",
              borderRadius: "10",
              "&:hover": {
                backgroundColor: "black",
                borderRadius: "10",
              },
            }}
          >
            Display all
          </Button>
        </Box>
      ) : (
        <Container
          sx={{
            //backgroundColor: "red",
            maxWidth: { lg: "1800px" },
          }}
        >
          {gridView ? (
            <BookGrid
              elements={filtered}
              style={{ flex: "0 0 100%", maxWidth: { lg: "1600px" } }}
            />
          ) : (
            <BookListBox
              boxTitle={"BOOKS"}
              elements={filtered}
              isMobile={isMobile}
            />
          )}
        </Container>
      )}
    </React.Fragment>
  );
}
