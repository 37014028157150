import { Stack } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Container } from '@mui/system';
import BookListBox from "../components/BookListBox";
import LoadingBox from "../components/LoadingBox";
import { ServerOperations } from "../db/ServerOperations";
import { useAuthContext } from "../hooks/useAuthContext";
import BookGridItem from "../components/list_item_layouts/BookGridItem";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { isbn } from '@form-validation/validator-isbn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    Box,
    Button,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    InputAdornment,
    IconButton
  } from "@mui/material";

export const Form = ({ handleFormContent, title, isValid, value, searchable, handleSearch, helperText, isFound, placeholder }) => {
  return (
    <>
        <Typography
            sx={{
              fontSize: "20px",
              display: "flex", 
              justifyContent: "start"
            }}
          >
            <b>{title}</b> <span style={{ fontWeight: 'bold', color: 'red' }}>*</span>
        </Typography>

        <form onChange={handleFormContent}>
          
          <TextField
            placeholder={placeholder}
            value={value}
            variant="outlined"
            fullWidth
            error={!isValid} // Use the isValid flag to determine if there's an error
            helperText={!isValid ? helperText: (!isFound ? "ISBN not found. Please fill title and author manually. " : "")} // Show helper text when there's an error
            sx={{
              backgroundColor: "grey.200",
              borderRadius: "10",
            }}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {searchable && 
                    (<SearchIcon style={{ color: "secondary" }} />)
                    }
                  </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                    {searchable && (
                      <Button variant="contained" color="primary" sx={{ minHeight: 5, minWidth: 5, padding: 1 }} onClick={handleSearch}>
                        <SearchIcon fontSize="small" style={{ color: "secondary" }} />
                      </Button>
                    )}
                    {isValid ? (
                      <IconButton edge="end" color="success">
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton edge="end" color="error">
                        <CancelOutlinedIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
          >
            <SearchIcon style={{ color: "secondary" }} />
          </TextField>
        </form>
    </>
  );
};