import MenuIcon from '@mui/icons-material/Menu';
import {
    Avatar,
    Box,
    IconButton
} from "@mui/material";
import { useAuthContext } from '../../hooks/useAuthContext';
import '../../i18n';
import logoImage from '../../theme/unlost_logo.png';

export const LogoSection = ({handleDrawerOpen, isSearchBarExpanded, isMobile}) => {
    const auth = useAuthContext();

    return (<Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flex: 20
                }} 
            >
                {auth && auth.name && 
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="primary"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon sx={{ fontSize: 25 }}/>
                        </IconButton>   
                }

                    {!(isSearchBarExpanded && isMobile) && <Avatar
                        alt="Logo"
                        src={logoImage}
                        sx={{
                            width: 120,
                            height: 20,
                            marginRight: 1,
                            marginLeft: 1,
                            display: 'flex',
                            borderRadius: 0,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => {
                            window.location.href = "/";
                        }}
                    />}
                </Box>
        )};