// Login page constants
export const loginTitle = "Sign in"
export const usernamePlaceholder = "Username"
export const passwordPlaceholder = "Password"
export const loginButtonText = "Login"
export const usernameErrorMessage = "Incorrect username"
export const passwordErrorMessage = "Incorrect password"

// Books index strings
export const availableBooksTitle = "Available books"
export const borrowedBooksTitle = "Borrowed books"
export const inMaintenanceBooksTitle = "In maintenance"

// Routing error
export const routingErrorMessage = "This page doesn't exist."

// Empty books message
export const noBooksMessage = "No books to show for the moment"

// Book error messages
export const networkErrorMessage = "Unable to load the book from server. Try again later."
export const notFoundErrorMessage = "The desired book doesn't exist, or is not available anymore."
export const alreadyLoanedErrorMessage = "The book is already loaned by someone else, or is temporarily unavailable."

// Snackbar messages
export const notifyBorrowerText = "An email has been sent to the borrower."
export const tempSnackBarText = "This feature will be available soon."
export const notifyBorrowerFailText = "Unable to send the email to the borrower. Please try again later."

// Borrow-return success messages
export const successfulBorrowMessage = "Thank you! Your request has been successfully processed. Enjoy your book!"
export const successfulReturnMessage = "Thank you! Feel free to explore the other books we have to offer."

// Borrow-return error messages
export const errorBorrowMessage = "Unable to borrow the book. Please report the incident to the library."
export const errorReturnMessage = "Unable to return the book. Please report the incident to the library."

// Name verification error messages
export const namePlaceholder = "Enter your full name…"

// Email verification error messages
export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const emailPlaceholder = "Enter your email address…"
export const badlyFormattedEmailMessage = "The email is badly formatted."
export const wrongBorrowEmailMessage = "The email address you entered is not the one used to borrow this book."

// Phone verification error messages
export const phonePlaceholder = "Enter your mobile phone number…"
export const badlyFormattedPhoneNumber = "Please enter a valid phone number."

// Borrow-return dialog texts
// export const returnDialogText = "To return this book, please enter the email address you used to borrow the book here."
export const returnDialogText = "To return this book, please enter your email address."
// export const returnPlaceholder = "Enter your email address or mobile phone number…"
// export const borrowDialogText = "To borrow this book, please enter your valid email address and phone number here. You will be automatically notified by email to return the book."
export const borrowDialogText = "To borrow this book, please enter your valid email address and phone number here."

export const emailTemplate = (bookTitle, borrowerName) => {
    return (
        `<h1>Book loan request</h1>
        <p>Hello ${borrowerName},</p>
        <p>You have requested to borrow the book <strong>${bookTitle}</strong> from the library. Please return the book within 15 days.</p>
        <p>Thank you!</p>
        <p>Library team</p>`
    )
}
    