import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BookCard from "../components/BookCard";
import ErrorBox from "../components/ErrorBox";
import LoadingBox from "../components/LoadingBox";
import { MuiPhone } from "../components/MuiPhone";
import SuccessBox from "../components/SuccessBox";
import { ServerOperations } from "../db/ServerOperations";
import { emailRegex } from "../lang/constants";
import { Book } from "../models/Book";

export default function BorrowReturnPage() {
  const { t } = useTranslation();
  // Fetching the desired book
  const params = useParams();
  const navigate = useNavigate();

  // State of page load => true means "is loading", false means "has finished loading"
  const [loading, setLoading] = React.useState(true);

  // Holds the retrieved book, or an undefined value if no book has been found
  const [book, setBook] = React.useState(Book.empty());
  const [errorMessage, setErrorMessage] = React.useState(
    t("alreadyLoanedErrorMessage")
  );

  // TODO: FETCH BOOK COVER FROM A BOOK API
  React.useEffect(() => {
    const asyncFetchBook = async () => {
      const book = await ServerOperations.getBook(params.id.toString());
      if (book === undefined) {
        setErrorMessage(t("notFoundErrorMessage"));
      }
      setBook(book);
      setLoading(false);
    };

    asyncFetchBook();
    return;
  }, [params.id, navigate]);

  // Holds the entered email address
  const [email, setEmail] = React.useState("");

  // Holds the entered phone number
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState("");

  // State of the email verification => false if no errors, true if bad email formatting
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

  // Success flag -> triggered when the borrow or return operation succeeded
  const [success, setSuccess] = React.useState(false);

  // Error flag -> triggered when the borrow or return operation failed
  const [error, setError] = React.useState(false);

  // Holds the message to display (different message for borrow & return operations)
  const [successMessage, setSuccessMessage] = React.useState("");

  // Borrow/return operation handlers
  const handleBorrow = async () => {
    try {
      // First we validate the email address
      if (!emailRegex.test(email)) {
        setEmailError(true);
        setEmailErrorMessage(t("badlyFormattedEmailMessage"));
      } else if (phone.length < 10) {
        console.log(phone);
        setPhoneError(true);
        setPhoneErrorMessage(t("badlyFormattedPhoneNumber"));
      } else {
        const response = await ServerOperations.setBookAsLoaned(
          book.getUUID(),
          { email: email, phone: phone }
        );
        if (response.ok) {
          closeDialog();
          setSuccessMessage(t("successfulBorrowMessage"));
          setSuccess(true);
        } else {
          closeDialog();
          setError(true);
          setErrorMessage(t("notFoundErrorMessage"));
        }
      }
    } catch (e) {
      closeDialog();
      setError(true);
      setErrorMessage(t("errorBorrowMessage"));
    }
  };

  const handleReturn = async () => {
    try {
      // First we validate the email address
      if (!emailRegex.test(email)) {
        setEmailError(true);
        setEmailErrorMessage(t("badlyFormattedEmailMessage"));
      } else {
        let response = await ServerOperations.setBookAsAvailable(
          book.getUUID(),
          { email: email }
        );
        if (response.ok) {
          closeDialog();
          setSuccessMessage(t("successfulReturnMessage"));
          setSuccess(true);
        } else {
          setEmailError(true);
          setEmailErrorMessage(t("wrongBorrowEmailMessage"));
        }
      }
    } catch (e) {
      closeDialog();
      setError(true);
      setErrorMessage(t("errorReturnMessage"));
    }
  };

  // Handlers for Dialog open/close actions
  const [dialogState, setDialogState] = React.useState(false);
  const openDialog = () => {
    setDialogState(true);
  };
  const closeDialog = () => {
    setDialogState(false);
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingBox />
      ) : (
        <Container
          sx={{
            position: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "400px",
            maxWidth: "90%",
            height: "auto",
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          {success ? (
            <SuccessBox successMessage={successMessage} />
          ) : (
            <>
              {book === undefined || book.isInMaintenance() || error ? (
                <ErrorBox errorMessage={errorMessage} />
              ) : (
                <Stack spacing={1}>
                  <BookCard
                    imgUrl={book.getCover()}
                    authorNames={book
                      .getAuthors()
                      .map((author) => author.getName())}
                    bookTitle={book.getTitle()}
                    bookLoanStatusString={t(book.getLoanStatusString(false))}
                    isBookLoaned={book.isLoaned()}
                    bookLoanDate={book.getLoanDate()}
                    showLoaner={false}
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={openDialog}
                    disabled={book.isInMaintenance()}
                  >
                    {book.isLoaned() ? t("returnBook") : t("borrowBook")}
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Container>
      )}

      {book !== undefined && (
        <Dialog open={dialogState} onClose={closeDialog}>
          <DialogTitle>
            {book.isLoaned() ? t("returnBook") : t("borrowBook")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {book.isLoaned() ? t("returnDialogText") : t("borrowDialogText")}
            </DialogContentText>
            <FormControl error={emailError} fullWidth variant="standard">
              <TextField
                autoFocus
                name="email"
                margin="dense"
                label={t("emailPlaceholder")}
                type="email"
                fullWidth
                variant="standard"
                onChange={(e) => setEmail(e.target.value)}
                color={emailError ? "error" : "primary"}
                aria-describedby="email-error-text"
              />
              {!book.isLoaned() && (
                <MuiPhone
                  name="phone"
                  margin="dense"
                  label={t("phonePlaceholder")}
                  type="tel"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setPhone(e)}
                  color={phoneError ? "error" : "primary"}
                  aria-describedby="phone-error-text"
                  defaultCountry={"ch"}
                />
              )}

              <FormHelperText id="phone-error-text">
                {phoneErrorMessage}
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>{t("cancel")}</Button>
            <Button
              onClick={
                book.isLoaned() ? () => handleReturn() : () => handleBorrow()
              }
              disabled={email === "" && phone === ""}
            >
              {t("confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
