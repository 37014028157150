import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import ImageComponent from "./ImageComponent";
import { statusColor } from "./utils";
import { useTranslation } from 'react-i18next';

const imgStyle = {
  margin: "auto",
  width: "auto",
  maxWidth: "100%",
  maxHeight: "40vh",
};

export default function BookCard(props) {
  const imgUrl = props.imgUrl;
  const authorNames = props.authorNames;
  const bookTitle = props.bookTitle;
  const bookLoanStatusString = props.bookLoanStatusString;
  const bookLoaner = props.bookLoaner;
  const isBookLoaned = props.isBookLoaned;
  const bookLoanDate = props.bookLoanDate;
  const bookDeadlineDate = props.bookDeadlineDate;
  const {t} = useTranslation();

  return (
    <Card variant="outlined">
      <CardMedia title={bookTitle}>
        <ImageComponent imgUrl={imgUrl} style={imgStyle} />
      </CardMedia>

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {bookTitle}
        </Typography>

        <Typography gutterBottom variant="body1" component="div">
          {authorNames.length > 0 ? authorNames.join(", ") : "Unknown Authors"}
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: statusColor(bookLoanStatusString),
              marginRight: "5px", // Adjust the margin as needed
            }}
          />
          {t(bookLoanStatusString)}
        </Typography>

        {isBookLoaned && (
          <Typography variant="body1" color="text.secondary">
            {bookLoaner && "Contact : "}
            <strong>{bookLoaner}</strong> <br />
            {bookLoaner && bookLoanDate} <br/>
            {bookLoaner && bookDeadlineDate}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
