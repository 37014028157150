import { useQuery } from "react-query";

const ImageComponent = ({ imgUrl, style }) => {
  // Fetch and cache the cover
  const { data: imageSrc, error } = useQuery(["coverImage", imgUrl], async () => {
    try {
      const response = await fetch(imgUrl);
      const data = await response.blob();
      return URL.createObjectURL(data);
    } catch (err) {
      console.error("Error fetching image:", err);
      return null; // Return a placeholder image URL here
    }
  });

  const fallbackImageSrc = "./default_book_cover.jpeg";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img
        src={imageSrc || fallbackImageSrc}
        style={{
          ...style,
          objectFit: "contain", // Preserve aspect ratio
          width: "100%",
          height: "100%",
        }}
        alt="Book Cover"
      />
    </div>
  );
};

export default ImageComponent;

