import { useState } from "react";
import { ServerOperations } from "../db/ServerOperations";
import { useAuthContext } from "../hooks/useAuthContext";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (username, password) => {
    setLoading(true);
    setError(null);

    // Response is an array => index 0 holds data if successful, index 1 holds error message if failed
    const response = await ServerOperations.login(username, password);
    if (response[0] != null) {
      localStorage.setItem("admin", JSON.stringify(response[0]));

      dispatch({ type: "LOGIN", payload: response[0] });

      setLoading(false);
    } else {
      setLoading(false);
      setError(response[1]);
    }
  };

  return { login, loading, error };
};
