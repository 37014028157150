import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function SuccessBox(props) {
  const successMessage = props.successMessage;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
      sx={{
        backgroundColor: "green",
        borderRadius: 2,
      }}
    >
      <Stack
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        <CheckCircleOutlineIcon
          fontSize="large"
          sx={{
            color: "white",
          }}
        />
        <Typography fontWeight="bold" color="white">
          Success
        </Typography>
        <Typography align="center" color="white">
          {successMessage}
        </Typography>
      </Stack>
    </Box>
  );
}
