import { Box } from "@mui/material";
import Lottie from "react-lottie";
import bookLoader from "../theme/bookLoader.json";

export default function LoadingBox() {
  const defaultOptions = {
    loop: true,
    speed: 10,
    autoplay: true,
    animationData: bookLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      paddingY={2}
    >
      <Lottie options={defaultOptions} height={200} width={200} />
    </Box>
  );
}
