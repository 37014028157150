import { createContext, useEffect, useReducer } from "react";

export const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        username: action.payload.username,
        name: action.payload.name,
        address: action.payload.address,
        accessToken: action.payload.accessToken,
      };
    case "LOGOUT":
      return {
        ...state,
        username: null,
        name: null,
        address: null,
        accessToken: null,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = (props) => {
  const [auth, dispatch] = useReducer(authReducer, {
    username: null,
    name: null,
    address: null,
    accessToken: null,
  });

  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("admin"));

    if (admin) {
      dispatch({ type: "LOGIN", payload: admin });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};
